import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import Button from './ButtonNew';

type ErrorPageProps = {
    errorNumber: number;
    errorTitle: string;
    description: string;
};

export default function ErrorPage(props: ErrorPageProps) {
    function backHandler() {
        window.history.back();
    }

    return (
        <article className="flex h-screen flex-col items-center justify-center bg-background">
            <section className="flex flex-col items-center">
                <h1 className="text-[144px]">{props.errorNumber}</h1>
                <h2 className="text-[72px]">{props.errorTitle}</h2>
                <p className="max-w-[400px] py-16">{props.description}</p>

                <Button buttonSize="lg" buttonStyle="primary" onClick={backHandler}>
                    <div className="flex flex-row items-center">
                        <ChevronLeftIcon className="m-1 h-5 w-5" /> Return to Previous Page
                    </div>
                </Button>
            </section>
        </article>
    );
}

export function PageNotFound() {
    return (
        <ErrorPage
            errorNumber={404}
            errorTitle="Page not found"
            description="Looks like you discovered a page that doesn't exist, or you don't have access to."
        />
    );
}

type ServerFailureProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
};

export function ServerFailure(props: ServerFailureProps) {
    if (props.error) {
        console.error(props.error);
    }
    return (
        <ErrorPage
            errorNumber={500}
            errorTitle="Internal Server Error"
            description="The server encountered an internal error or misconfiguration and was unable to complete your request."
        />
    );
}

export function Forbidden() {
    return (
        <ErrorPage
            errorNumber={403}
            errorTitle="Forbidden"
            description="You don't have permission to access this resource."
        />
    );
}

export function ServiceUnavailable() {
    return (
        <ErrorPage
            errorNumber={503}
            errorTitle="Service Unavailable"
            description="The server is temporarily busy. Please try again later."
        />
    );
}

export function ErrorStatusPage({ status }: { status?: number }) {
    switch (status) {
        case 503:
            return <ServiceUnavailable />
        case 403:
            return <Forbidden />
        case 404:
            return <PageNotFound />;
        default:
            return <ServerFailure />;
    }
}