import { Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser';
import * as polarisConfig from './common/PolarisConfig';

type MSALConfig = Configuration;

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: polarisConfig.userFlow,
    },
    authorities: {
        signUpSignIn: {
            authority: polarisConfig.authority,
        },
    },
    authorityDomain: polarisConfig.authorityDomain,
};

export function makeMSALConfig(): MSALConfig {
    return msalConfig;
}

export function makeLoginConfig(): RedirectRequest {
    return loginConfig;
}

const loginConfig: RedirectRequest = {
    scopes: polarisConfig.scopes,
};

const msalConfig: MSALConfig = {
    auth: {
        clientId: polarisConfig.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: b2cPolicies.authorityDomain, // Mark your B2C tenant's domain as trusted.
        redirectUri: polarisConfig.redirectUri, //'https://localhost:3000', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: polarisConfig.postLogoutRedirectUri, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [],
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ['openid', 'profile'],
    loginHint: 'example@domain.net',
};
