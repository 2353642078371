export type WindowWithAuthInfo = Window & {
    Polaris: PolarisAuthInfo;
};

export type PolarisFeatures = {
    notifications: boolean;
};

export type PolarisAuthInfo = {
    authInfo: PolarisAuth;
    adminApiInfo: PolarisApiInfo;
    communityApiInfo: PolarisApiInfo;
    mediaApiInfo: PolarisApiInfo;
    clientApiInfo: PolarisApiInfo;
    documentApiInfo: PolarisApiInfo;
    features: PolarisFeatures;
};

export type PolarisAuth = {
    clientId: string;
    authority: string;
    authorityChangePwd: string;
    authorityDomain: string[];
    userFlow: string;
    userFlowChangePwd: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
};

export type PolarisApiInfo = {
    api: string;
    scope: string;
};

export let clientId: string = '925e15de-c583-44a3-a30c-6fcfa3e75048';
export let authority: string =
    'https://authentication.devcspace.com/cspacepolarisdev.onmicrosoft.com/B2C_1_LocalHost_ClientPortal';
export let authorityChangePwd: string =
    'https://authentication.devcspace.com/cspacepolarisdev.onmicrosoft.com/B2C_1_PasswordReset';
export let userFlow: string = 'B2C_1_LocalHost_ClientPortal';
export let userFlowChangePwd: string = 'B2C_1_PasswordReset';
export let authorityDomain: string[] = ['https://authentication.devcspace.com'];
export let adminApiScope: string =
    'https://cspacepolarisdev.onmicrosoft.com/f21e9619-914f-4170-b764-452e56550517/Polaris.Admin.Api.Access';
export let adminApiUri: string = 'https://localhost:7050';
export let clientApiUri: string = 'https://localhost:7060';
export let clientApiScope: string =
    'https://cspacepolarisdev.onmicrosoft.com/0b66a74c-4916-483a-ab3e-0f4ed64f9683/Client.Api.All';
export let mediaApiUri: string = 'https://localhost:7080';
export let mediaApiScope: string =
    'https://cspacepolarisdev.onmicrosoft.com/83450352-5f47-402b-b3b2-9129a51a35dd/Polaris.Media.Api.Access';
export let documentApiUri: string = 'https://localhost:7115';
export let documentApiScope: string =
    'https://cspacepolarisdev.onmicrosoft.com/7587716f-7ec6-4de3-9ac8-9f5ef6ec17e5/Polaris.Document.Api.Access';
export let postLogoutRedirectUri: string = 'http://localhost:3000';
export let redirectUri: string = 'http://localhost:3000';

export let scopes: string[] = [adminApiScope, mediaApiScope, clientApiScope, documentApiScope];

export let polarisFeatures: PolarisFeatures = {
    notifications: true,
};

(function autoload() {
    load();
})();

function load() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const info = (window as any as WindowWithAuthInfo).Polaris;

    if (info) {
        clientId = info.authInfo?.clientId ?? '';
        authority = info.authInfo?.authority;
        authorityChangePwd = info.authInfo?.authorityChangePwd;
        userFlow = info.authInfo?.userFlow;
        userFlowChangePwd = info.authInfo?.userFlowChangePwd;
        authorityDomain = info.authInfo?.authorityDomain;
        postLogoutRedirectUri = info.authInfo?.postLogoutRedirectUri ?? '';
        redirectUri = info.authInfo?.redirectUri ?? '';

        adminApiScope = info.adminApiInfo?.scope ?? '';
        adminApiUri = info.adminApiInfo?.api ?? '';
        mediaApiUri = info.mediaApiInfo?.api ?? '';
        mediaApiScope = info.mediaApiInfo?.scope ?? '';
        clientApiUri = info.clientApiInfo?.api ?? '';
        clientApiScope = info.clientApiInfo?.scope ?? '';
        documentApiUri = info.documentApiInfo?.api ?? '';
        documentApiScope = info.documentApiInfo?.scope ?? '';

        scopes = [];
        if (info.adminApiInfo?.scope) {
            scopes.push(info.adminApiInfo.scope);
        }
        if (info.mediaApiInfo?.scope) {
            scopes.push(info.mediaApiInfo.scope);
        }
        if (info.clientApiInfo?.scope) {
            scopes.push(info.clientApiInfo.scope);
        }
        if (info.documentApiInfo?.scope) {
            scopes.push(info.documentApiInfo.scope);
        }

        polarisFeatures = info.features;
    }
}
