import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { makeMSALConfig } from './authConfig';
import { login, msalInstance } from './common/azure/auth';
import './index.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';

import reportWebVitals from './reportWebVitals';
import LoginInterceptor from './LoginInterceptor';
import { ReactChildren } from './common/utils';

/**
 * This is a base file for all other index.tsx files
 * Which are each tied to an index.html file
 */

const msalConfig = makeMSALConfig();
login(msalConfig);

type Props = {
    children: ReactChildren;
};

export default function IndexBase({ children }: Props) {
    return (
        <MsalProvider instance={msalInstance!}>
            <UnauthenticatedTemplate>
                <LoginInterceptor />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
        </MsalProvider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
