import {
    Configuration,
    InteractionRequiredAuthError,
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
} from '@azure/msal-browser';
import * as polarisConfig from '../PolarisConfig';

export const adminUI = 'D4841B6E-5474-49A7-A115-2AFE0340931F';
export const portalUI = '06EA8439-D723-4E6F-BFE2-CB978A502459';

export let appId: string = portalUI;
export let msalInstance: PublicClientApplication | null = null;

// declare scopes and uris
export const adminApiScope = polarisConfig.adminApiScope;
export const adminApiUri = polarisConfig.adminApiUri;
export const clientApiScope = polarisConfig.clientApiScope;
export const clientApiUri = polarisConfig.clientApiUri;
export const mediaApiScope = polarisConfig.mediaApiScope;
export const mediaApiUri = polarisConfig.mediaApiUri;
export const documentApiScope = polarisConfig.documentApiScope;
export const documentApiUri = polarisConfig.documentApiUri;

//We may want to streamline this a bit. Login is called a lot.
//We should act on the events,
export function login(msalConfig: Configuration) {
    //TODO: Seems to come in here a lot
    const instance = new PublicClientApplication(msalConfig);
    setMSALInstance(instance);

    updateAccount();
}

export function logout() {
    const account = msalInstance?.getActiveAccount();

    const logoutRequest = {
        account: msalInstance?.getAccountByHomeId(account!.homeAccountId),
        postLogoutRedirectUri: polarisConfig.postLogoutRedirectUri,
    };
    msalInstance!.logoutRedirect(logoutRequest);
}

export function changePasswordUri() {
    const encodedRedirectUri = encodeURIComponent(polarisConfig.redirectUri);
    const baseUri = polarisConfig.authority.replace(polarisConfig.userFlow, '');
    const uri = `${baseUri}oauth2/v2.0/authorize?p=${polarisConfig.userFlowChangePwd}&client_id=${polarisConfig.clientId}&nonce=defaultNonce&redirect_uri=${encodedRedirectUri}&scope=openid&response_type=id_token&prompt=login`;
    return uri;
}

export function setMSALInstance(instance: PublicClientApplication) {
    msalInstance = instance;
}

export function updateAccount() {
    if (!msalInstance) {
        console.error('Auth.updateAccount: Called auth.updateAccount with null msalInstance');
        return;
    }

    //Got an active, we are good, nothing else to do
    if (msalInstance.getActiveAccount()) {
        //No active but we got accounts - set the first  as active
    } else if (msalInstance.getAllAccounts().length > 0) {
        const a = msalInstance.getAllAccounts()[0];
        msalInstance.setActiveAccount(a);

        //No accounts, add a callback for the successful login.
    } else {
        msalInstance.addEventCallback((event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                msalInstance?.setActiveAccount(account);
            }
        });
    }
}

// https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial

export async function getAuthToken(scopes: string[]): Promise<string | null> {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    if (msalInstance == null) {
        return null;
    }

    const request = { scopes: scopes };

    try {
        const result = await msalInstance.acquireTokenSilent(request);
        const accessToken = result.accessToken;

        if (!accessToken) {
            await msalInstance.acquireTokenRedirect(request);

            return await getAuthToken(scopes);
        }
        return accessToken;
    } catch (error) {
        console.error(error);
        console.warn('silent token acquisition fails. acquiring token using popup');
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            await msalInstance.acquireTokenRedirect(request);
        } else {
            console.error(error);
        }
    }
    return null;
}

export function updateAppId(id: string) {
    appId = id;
}
