import React from 'react';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import LoadingFullScreen from './common/components/LoadingFullScreen';

interface LoginInterceptorProps {}

const LoginInterceptor: React.FC<LoginInterceptorProps> = () => {
    useMsalAuthentication(InteractionType.Redirect);

    return (
        <LoadingFullScreen />
    );
};

export default LoginInterceptor;
