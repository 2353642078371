import { mediaApiUri, getAuthToken, mediaApiScope } from '../azure/auth';

export const activityTileUpload = (clientId: string, accountId: string, activityId: string) =>
    `${mediaApiUri}/Activity/tile/${clientId}/${accountId}/${activityId}`;
export const activityContentUpload = (clientId: string, accountId: string, activityId: string) =>
    `${mediaApiUri}/Activity/content/${clientId}/${accountId}/${activityId}`;
export const activityContentDelete = (clientId: string, accountId: string, activityId: string) =>
    `${mediaApiUri}/Activity/${clientId}/${accountId}/${activityId}`;
export const accountMediaDownload = (clientId: string, accountId: string, mediaId: string) =>
    `${mediaApiUri}/Activity/${clientId}/${accountId}/${mediaId}`;

export const accountHeroUpload = (clientId: string, accountId: string) =>
    `${mediaApiUri}/Account/Hero/${clientId}/${accountId}`;
export const accountMastheadUpload = (clientId: string, accountId: string) =>
    `${mediaApiUri}/Account/Masthead/${clientId}/${accountId}`;
export const accountLogoUpload = (clientId: string, accountId: string) =>
    `${mediaApiUri}/Account/Logo/${clientId}/${accountId}`;

export const userProfileUpload = (userId: string) => `${mediaApiUri}/User/profile/${userId}`;
export const currentUserProfileUpload = () => `${mediaApiUri}/User/profile`;

export const accountLogoUrl = async (clientId: string, accountId: string) => {
    const t = await tokenParam();
    return `${mediaApiUri}/Account/Logo/${clientId}/${accountId}?${t}`;
};

export const accountLogoUrlCacheBuster = async (clientId: string, accountId: string) => {
    const t = await tokenParam();
    const ticks = new Date().getTime();
    return `${mediaApiUri}/Account/Logo/${clientId}/${accountId}?${t}&ticks=${ticks}`;
};

export const currentUserProfileImage = async (tickCount?: number | null) => {
    let t = await tokenParam();
    if (tickCount) {
        t = `${t}&ticks=${tickCount}`;
    }
    return `${mediaApiUri}/User/profile?${t}`;
};

export const currentUserProfileImageCacheBuster = async () => {
    const t = await tokenParam();
    const ticks = new Date().getTime();
    return `${mediaApiUri}/User/profile?${t}&ticks=${ticks}`;
};

export const globalHeroDownload = async (mediaId: string) => {
    const t = await tokenParam();
    return `${mediaApiUri}/Global/Hero/${mediaId}?${t}`;
};

export const globalHeroUpload = `${mediaApiUri}/Global/Hero`;

export const teamMemberHeroUpload = (clientId: string, accountId: string) =>
    `${mediaApiUri}/User/TeamMemberHero/${clientId}/${accountId}`;

export const teamMemberHeroDownload = async (clientId: string, accountId: string, mediaId: string) => {
    const t = await tokenParam();
    return `${mediaApiUri}/User/TeamMemberHero/${clientId}/${accountId}/${mediaId}?${t}`;
};

export const teamMemberHeroCacheBuster = async (clientId: string, accountId: string, mediaId: string) => {
    const t = await tokenParam();
    const ticks = new Date().getTime();
    return `${mediaApiUri}/User/TeamMemberHero/${clientId}/${accountId}/${mediaId}?${t}&ticks=${ticks}`;
};

export const globalTileDownload = async (mediaId: string) => {
    const t = await tokenParam();
    return `${mediaApiUri}/Global/Tile/${mediaId}?${t}`;
};

export const globalTileUpload = `${mediaApiUri}/Global/Tile`;

export const userProfileImage = async (userId: string) => {
    const t = await tokenParam();
    return `${mediaApiUri}/User/profile/${userId}?${t}`;
};

export const userProfileImageCacheBuster = async (userId: string) => {
    const t = await tokenParam();
    const ticks = new Date().getTime();
    return `${mediaApiUri}/User/profile/${userId}?${t}&ticks=${ticks}`;
};

const tokenParam = async () => {
    const token = await getAuthToken([mediaApiScope]);
    return `access_token=${token}`;
};
