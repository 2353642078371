import { useQueryClient } from '@tanstack/react-query';
import { CurrentUserViewModel as AdminUser } from './adminApi/AdminApiTypes';
import { CurrentUserViewModel as AccountUser } from './clientApi/ClientApiTypes';
import { accountId, accountShortName, clientId, clientShortName } from '../client/Tenant';
import { EMPTY_ID } from './consts/defaults';

/** Force Change
 * Used in Admin FrontEnd. Checks if the Admin user has an Admin Permission
 */
export const hasAdminPermission = (user: AdminUser | null | undefined, permission: string): boolean => {
    const adminId = EMPTY_ID;
    return hasSystemPermission(user, adminId, permission);
};

/**
 * Returns true if user has any of the listed permissions
 * @param user
 * @param permissions
 * @returns boolean
 */
export const hasAnyAdminPermissions = (user: AdminUser | null | undefined, permissions: string[]): boolean => {
    return permissions.some((p) => hasAdminPermission(user, p));
};

/**
 * Returns true if user has all admin permissions listed
 * @param user
 * @param permissions
 * @returns boolean
 */
export const hasAllAdminPermissions = (user: AdminUser | null | undefined, permissions: string[]): boolean => {
    return permissions.every((p) => hasAdminPermission(user, p));
};

/**
 * Used in Admin FrontEnd. Checks if the Admin user has a permission for an Account
 */
const hasSystemPermission = (user: AdminUser | null | undefined, systemId: string, permission: string): boolean => {
    if (!user || !user.permissions) {
        return false;
    }

    const pList = user.permissions[systemId];

    if (!pList) {
        return false;
    }
    return pList.some((p) => p === permission);
};

/**
 * Used in Client FrontEnd. Checks if user has an Account permission
 */
export const hasAccountPermission = (user: AccountUser | null | undefined, permission: string): boolean => {
    if (!user) {
        return false;
    }
    return user.permissions?.some((p) => p === permission) ?? false;
};

/**
 * Account User has any of the listed permissions
 * @param user: AccountUser
 * @param permissions
 * @returns
 */
export const hasAnyAccountPermissions = (user: AccountUser | null | undefined, permissions: string[]): boolean => {
    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    }

    return permissions.some((p) => hasAccountPermission(user, p));
};

/**
 * Account User has All of the listed permission
 * @param user
 * @param permissions
 * @returns
 */
export const hasAllAccountPermissions = (user: AccountUser | null | undefined, permissions: string[]): boolean => {
    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    }
    return permissions.every((p) => hasAccountPermission(user, p));
};

// new stuff
type WithPermissionProps = {
    permission: string;
    children: JSX.Element;
    permissionDeniedElem?: JSX.Element;
};

type WithPermissionsProps = {
    permissions: string[] | string;
    children: JSX.Element;
    permissionDeniedElem?: JSX.Element;
};

/**
 * Component to display content if Admin user has the listed permission
 */
export function WithAdminPermission(props: WithPermissionProps): JSX.Element | null {
    const userData = useAdminUser();

    const permissions = props.permission;

    const isOk = hasAdminPermission(userData, permissions);

    if (isOk) {
        return props.children;
    }

    return props.permissionDeniedElem ?? null;
}

/**
 * Component to display content if Admin user has any of the listed permissions
 */
export function WithAnyAdminPermissions(props: WithPermissionsProps): JSX.Element | null {
    const userData = useAdminUser();

    let permissions = props.permissions;
    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    }

    const isOk = hasAnyAdminPermissions(userData, permissions);

    if (isOk) {
        return props.children;
    }

    return props.permissionDeniedElem ?? null;
}

/**
 * Component to display content if Admin user has all of the listed permissions
 */
export function WithAllAdminPermissions(props: WithPermissionsProps): JSX.Element | null {
    const userData = useAdminUser();

    let permissions = props.permissions;
    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    }

    const isOk = hasAllAdminPermissions(userData, permissions);

    return isOk ? props.children : props.permissionDeniedElem ?? null;
}

type AccountUserData = AccountUser | undefined;
type AdminUserData = AdminUser | undefined;

/**
 * Component to display content if Account user has listed permission
 */
export function WithAccountPermission(props: WithPermissionProps): JSX.Element | null {
    const userData = useAccountUser();
    const permissions = props.permission;

    const isOk = hasAccountPermission(userData, permissions);

    return isOk ? props.children : props.permissionDeniedElem ?? null;
}

/**
 * Component to display content if Account user has any of the listed permissions
 */
export function WithAnyAccountPermissions(props: WithPermissionsProps): JSX.Element | null {
    const userData = useAccountUser();
    let permissions = props.permissions;
    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    }
    const isOk = hasAnyAccountPermissions(userData, permissions);

    return isOk ? props.children : props.permissionDeniedElem ?? null;
}

/**
 * Component to display content if Account user has any of the listed permissions
 */
export function WithAllAccountPermissions(props: WithPermissionsProps): JSX.Element | null {
    const userData = useAccountUser();
    let permissions = props.permissions;
    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    }
    const isOk = hasAllAccountPermissions(userData, permissions);

    return isOk ? props.children : props.permissionDeniedElem ?? null;
}

/**
 * Hook that returns the current Admin user
 * @returns Current Admin user
 */
export function useAdminUser(): AdminUserData {
    const queryClient = useQueryClient();
    return queryClient.getQueryData(['adminCurrentUser']);
}

export type TenantInfo = {
    clientId: string;
    accountId: string;
    clientShortName: string;
    accountShortName: string;
};

/**
 *
 * @returns Returns information about the current Tenant
 */
export function useTenant(): TenantInfo {
    return {
        clientId: clientId,
        accountId: accountId,
        clientShortName: clientShortName,
        accountShortName: accountShortName,
    };
}

/**
 * Hook that returns the current Account user
 * @returns Current Account User
 */
export function useAccountUser(): AccountUserData {
    const queryClient = useQueryClient();
    return queryClient.getQueryData(['currentUser', clientId, accountId]);
}

/**
 * Checks if a permission exists for the current Admin user
 * @param permission
 * @returns
 */
export function useAdminPermission(permission: string): boolean {
    const userData = useAdminUser();
    return hasAdminPermission(userData, permission);
}

/**
 * Checks if a permission exists for the current Account User
 * @param permission
 * @returns
 */
export function useAccountPermission(permission: string): boolean {
    const userData = useAccountUser();
    return hasAccountPermission(userData, permission);
}

type MissingPermissionsProps = {
    message?: JSX.Element | string;
};

/**
 * Displays Permission Denied message
 */
export function PermissionDenied(props: MissingPermissionsProps): JSX.Element {
    const message = props.message ?? 'You do not have permissions to see this';

    return (
        <section className="permissions-missing m-4 rounded-sm border border-error-700 bg-error-50 p-4 text-error-700">
            <h4>Permission Denied</h4>
            {message}
        </section>
    );
}
