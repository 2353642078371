import { ForwardedRef, forwardRef, SyntheticEvent } from 'react';
import { BUTTON_SIZES, BUTTON_STYLES_NEW } from './consts/enums';
import { ReactChildren } from './utils';

type Props = {
    children: ReactChildren;
    onClick?: (evt?: SyntheticEvent) => void;
    onMouseDown?: (evt?: SyntheticEvent) => void;
    isDisabled?: boolean;
    buttonType?: 'button' | 'submit' | 'reset';
    fullWidth?: boolean;
};

type ButtonStyleOptions = {
    buttonStyle?: keyof typeof BUTTON_STYLES_NEW;
    buttonSize?: keyof typeof BUTTON_SIZES;
    isIconOnly?: boolean;
    className?: string;
    usePadding?: boolean;
    useUppercase?: boolean;
    fullWidth?: boolean;
    textColor?: string;
};

const USE_PADDING_DEFAULT = true;
const USE_UPPERCASE_DEFAULT = true;
const BUTTON_STYLE_DEFAULT = 'primary';
const BUTTON_SIZE_DEFAULT = 'base';

export function buttonStyleClass({
    className,
    textColor,
    fullWidth,
    isIconOnly,
    buttonStyle = BUTTON_STYLE_DEFAULT,
    buttonSize = BUTTON_SIZE_DEFAULT,
    usePadding = USE_PADDING_DEFAULT,
    useUppercase = USE_UPPERCASE_DEFAULT,
}: ButtonStyleOptions) {
    const baseTypography = 'font-bold tracking-normal';
    buttonSize = buttonSize ?? 'base';

    const styleVariants = {
        [BUTTON_STYLES_NEW.primary]: `bg-secondary-500 ${
            textColor ? textColor : 'text-purewhite'
        } border hover:bg-secondary-800 hover:underline focus:bg-secondary-800 disabled:bg-background disabled:text-inactive disabled:border disabled:border-inactive disabled:hover:border-background disabled:hover:bg-white`,
        [BUTTON_STYLES_NEW.secondary]: `bg-purewhite ${
            textColor ? textColor : 'text-secondary-500'
        } border border-secondary-500 border-solid hover:bg-secondary-50 hover:underline focus:bg-secondary-800 focus:text-white disabled:bg-white disabled:text-black disabled:border disabled:border-inactive disabled:hover:border-primary-50`,
        [BUTTON_STYLES_NEW.text]: `bg-transparent ${
            textColor ? textColor : 'text-primary-500'
        } hover:text-primary-800 focus:text-primary-300 disabled:text-black`,
    };

    const sizeVariants = {
        [BUTTON_SIZES.xs]: 'text-xs leading-[18px]',
        [BUTTON_SIZES.sm]: 'text-xs leading-[18px]',
        [BUTTON_SIZES.base]: 'text-sm leading-[21px]',
        [BUTTON_SIZES.lg]: 'text-base leading-[24px]',
        [BUTTON_SIZES.xl]: 'text-base leading-[24px]',
    };

    const padding = {
        [BUTTON_SIZES.xs]: 'pl-3 pr-[12px] py-[8px]',
        [BUTTON_SIZES.sm]: 'pl-3 pr-[12px] py-[8px]',
        [BUTTON_SIZES.base]: 'pl-5 pr-[20px] py-[10px]',
        [BUTTON_SIZES.lg]: 'pl-5 pr-[20px] py-[12px]',
        [BUTTON_SIZES.xl]: 'pl-6 pr-[24px] py-[14px]',
    };

    const iconOnlyStyle = isIconOnly ? 'hover:bg-background p-[14px] rounded-full' : '';
    const btnPadding = usePadding ? padding[buttonSize] : '';
    const btnUppercase = useUppercase ? 'uppercase' : '';
    const btnStyle = styleVariants[buttonStyle];
    const btnSize = sizeVariants[buttonSize];
    const width = fullWidth ? 'w-full' : '';

    return `${
        className ?? ''
    } ${baseTypography} ${btnUppercase} ${btnPadding} ${btnStyle} ${btnSize} ${width} ${iconOnlyStyle}`;
}

function ButtonInner(
    {
        children,
        onClick,
        onMouseDown,
        isIconOnly,
        className,
        fullWidth,
        textColor,
        buttonStyle = BUTTON_STYLE_DEFAULT,
        buttonSize = BUTTON_SIZE_DEFAULT,
        usePadding = USE_PADDING_DEFAULT,
        useUppercase = USE_UPPERCASE_DEFAULT,
        isDisabled = false,
        buttonType = 'button',
    }: Props & ButtonStyleOptions & React.HTMLProps<HTMLButtonElement>,
    ref: ForwardedRef<HTMLButtonElement>
) {
    const cn = buttonStyleClass({
        buttonStyle,
        buttonSize,
        isIconOnly,
        className,
        usePadding,
        useUppercase,
        fullWidth,
        textColor,
    });

    return (
        <button
            ref={ref}
            type={buttonType}
            disabled={isDisabled}
            className={cn}
            onClick={onClick}
            onMouseDown={onMouseDown}
        >
            {children}
        </button>
    );
}

export default forwardRef(ButtonInner);
