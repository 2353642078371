export const BUTTON_STYLES: Record<string, Record<string, string>> = {
    disabled: {
        textColor: 'text-cs-disabled',
        borderColor: 'border-cs-disabled',
        bgColor: 'bg-white',
    },
    orange: {
        textColor: 'text-secondary-500',
        borderColor: 'border-secondary-500',
        bgColor: 'bg-white',
        hoverShadow: 'hover:shadow-xl',
    },
    white: {
        textColor: 'text-white',
        bgColor: 'bg-secondary-500',
        borderColor: 'border-secondary-500',
        hoverShadow: 'hover:shadow-xl',
    },
    black: {
        textColor: 'text-black',
        bgColor: 'bg-white',
        borderColor: 'border-black',
        hoverShadow: 'hover:shadow-xl',
    },
};

export const BUTTON_STYLES_NEW = {
    primary: 'primary',
    secondary: 'secondary',
    text: 'text',
};

export const BUTTON_SIZES = {
    xs: 'xs',
    sm: 'sm',
    base: 'base',
    lg: 'lg',
    xl: 'xl',
};

export const CURRENCIES = {
    usd: 'USD',
    eur: 'EUR',
    gbp: 'GBP',
    percentages: 'Percentages',
    credits: 'Credits',
};

export const CHART_ITEMS_ORDINAL = {
    TOTAL: 0,
    REMAINING: 10,
    UNKNOWN: 10,
};

export const FIELD_TOKENS = {
    FIELD: 'field',
    NAME: 'name',
    VALUE: 'value',
};

export const enum HeaderIndicatorType {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
}
