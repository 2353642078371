import { useEffect, useState } from 'react';

type MediaImageParams = React.ImgHTMLAttributes<HTMLImageElement> & {
    urlFunc: () => Promise<string>;
    imageIdx?: number;
    imageSize?: 'smallIcon' | 'default';
};

const styleVariants = {
    smallIcon: 'w-8 h-8 rounded-full object-cover',
    default: '',
};

/// <summary>
/// Wraps a image element to allow it to construct the url needed to get the image from our media service.
/// The function is there to attach the access_token to the url, which will allow the request to go
/// through the OAuth2 security system.
/// <summary>
export function MediaImage(params: MediaImageParams) {
    const { imageSize = 'default', className = '', ...props } = params;
    const [imgProps, setImgProps] = useState<React.ImgHTMLAttributes<HTMLImageElement>>({ ...props });

    useEffect(() => {
        const exec = async () => {
            const u = await params.urlFunc();
            setImgProps({ ...imgProps, src: u });
        };

        exec();
    }, [params.imageIdx]);

    const newProps = {...imgProps, urlFunc: undefined, imageIdx: undefined};
    delete newProps.urlFunc;
    delete newProps.imageIdx;
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img className={`${styleVariants[imageSize]} ${className}`} {...newProps} />;
}
