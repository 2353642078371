import { AccountViewModel } from '../common/clientApi/ClientApiTypes';

export let clientId: string = '';
export let accountId: string = '';

export let clientShortName: string = '';
export let accountShortName: string = '';
export let accountName: string = '';

export let hasTenantInfo: boolean = !!clientId && !!accountId && !!clientShortName && !!accountShortName;

export let sitePrefix: string = 'client';

export const makeUrl = (url: string): string => {
    if (sitePrefix && sitePrefix.length > 0) {
        return `/${clientShortName}/${accountShortName}`;
    }
    return `/${clientShortName}/${accountShortName}`;
};

export const updateViaAccount = (c: AccountViewModel) => {
    clientId = c.clientId;
    accountId = c.accountId;
    clientShortName = c.clientShortName ?? '';
    accountShortName = c.shortName ?? '';
    accountName = c.displayName ?? '';
};

export const extractTenantParams = (pathName: string): string[] => {
    if (sitePrefix) {
        pathName = pathName.replace(`/${sitePrefix}`, '');
    }

    const parts = pathName.split('/').filter((x) => x !== '');

    return parts;
};
