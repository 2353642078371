import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from './ErrorPage';

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getDrivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        if (error.message.includes('dynamically imported module')) {
            window.document.location.reload();
            return;
        }
        this.setState({ hasError: true });
        console.error('Uncaught error:', error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <ErrorPage
                    errorNumber={500}
                    errorTitle="Error Occurred"
                    description="Sorry, an unknown error occurred. Please refresh and try again."
                />
            );
        }

        return this.props.children;
    }
}
